import Link from "next/link";
import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import Slider from "../../UI/toast/Carousel/Slider";
import { MdOutlineVilla } from "react-icons/md";
import { capture } from "../../../utils/analytics/capture";

function HomePropertyHit({
  hit,
  onClick,
  sendEvent,
  loadingProperty,
  setLoadingProperty,
  dataSource,
  pageSource,
}) {
  const [propImages, setPropImages] = useState(null);
  // const [loadingProperty, setLoadingProperty] = useState(null)

  let propertyID = dataSource === "FIRESTORE" ? hit.propertyID : hit.objectID;

  //console.log(hit)
  let featureImage = Object.entries(hit.images).find(
    (el) => el[1]?.isFeatureImage === true
  );
  const [isIntersecting, setIntersecting] = useState(false);
  // console.log(Object.entries(hit.images))
  const { ref } = useInView({
    triggerOnce: true,
    rootMargin: "10%",
    onChange: (inView) => {
      if (inView) {
        setIntersecting(true);
        // console.log(hit.propertyName + " :In View");

        capture({
          category: "browse",
          event: "impression",
          properties: {
            ...hit,
            type: "property",
          },
        });
      }
    },
  });

  // useEffect(() => {
  //   const dbRef = rtRef(getDatabase());
  //   get(child(dbRef, `PropertyImages/${propertyID}`))
  //     .then((snapshot) => {
  //       if (snapshot.exists()) {
  //         setPropImages(snapshot.val());
  //       } else {
  //         console.log("No data available");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }, [hit, propertyID]);

  // console.log(propImages);

  const title = `${
    (hit.noOfBedrooms === 0
      ? hit.propertyType
      : hit.noOfBedrooms + " bedroom " + hit.propertyType) +
    " for " +
    hit.listingType.toLowerCase() +
    " in " +
    hit.propertyTown.townName +
    (hit.listingType === "RENT"
      ? ` - Kshs ${Intl.NumberFormat().format(hit.monthlyRent)}/mo - `
      : ` - Kshs ${Intl.NumberFormat().format(hit.price)} - `) +
    // " " +
    // (property.propertyArea.descriptor
    //   ? property.propertyArea.descriptor.toLowerCase()
    //   : " ") +
    // " " +
    hit.propertyArea.areaName +
    ", " +
    hit.propertyCounty.countyName
  }`;

  return (
    <div
      ref={ref}
      className={
        " flex h-64 flex-shrink-0 cursor-pointer  select-none snap-center flex-col rounded-md border border-gray-200 bg-white shadow hover:shadow-md lg:w-full" +
        (pageSource === "PROPERTY" ? " w-full lg:w-full " : " w-[80%] ")
      }
    >
      <div className=" relative h-3/5 w-full rounded-t-md bg-gray-100 ">
        {loadingProperty === null ? (
          <div className=" absolute flex h-full w-full items-center justify-center">
            <MdOutlineVilla className=" h-10 w-10 text-gray-500" />
          </div>
        ) : null}
        {/* {propImages !== null ? ( */}
        <Slider
          propertyImages={hit?.images ?? null}
          propertyData={hit}
          sendEvent={sendEvent}
          loadingProperty={loadingProperty}
          setLoadingProperty={setLoadingProperty}
          isIntersecting={isIntersecting}
        />
        {/* ) : null} */}
        {hit.listingType === "RENT" ? (
          <span className="  absolute left-3 top-3 rounded-full  bg-ula-blue px-4 py-1 text-xs font-semibold uppercase text-blue-100">
            {"For rent"}
          </span>
        ) : (
          <span className="  absolute left-3 top-3 rounded-full   bg-pink-700 px-4 py-1 text-xs font-semibold uppercase text-blue-100">
            {"For sale"}
          </span>
        )}
      </div>

      <Link
        prefetch={false}
        key={hit.propertyID}
        href={`/homes/${hit.slugShort}`}
        passHref
      >
        <a
          // href="/"
          onClick={() => {
            if (sendEvent) {
              sendEvent("click", hit, "property_clicked");
            }
          }}
          className=" flex  h-[50%] flex-row justify-between rounded-b-md px-4 py-3"
        >
          <div className="flex w-full  flex-col">
            <h4
              title={title}
              className=" mb-1 truncate text-base font-semibold text-gray-600  lg:font-normal "
            >
              {title}
            </h4>
            <div className=" flex flex-row items-center justify-between">
              {hit.listingType === "RENT" ? (
                <h5 className=" text-base font-semibold">
                  Kshs {Intl.NumberFormat().format(hit.monthlyRent)}/mo
                </h5>
              ) : (
                <h5 className=" text-base font-semibold">
                  Kshs {Intl.NumberFormat().format(hit.price)}
                </h5>
              )}
              <h5 className=" text-xs font-semibold uppercase  text-gray-600">
                {hit.propertyType}
              </h5>
            </div>
            <div className="flex flex-row space-x-2 text-sm text-gray-700">
              <h6>{hit.noOfBedrooms}bd </h6>
              <h6>{hit.noOfBathrooms}ba </h6>
            </div>

            <h5 title={title} className=" truncate text-gray-700">
              {hit.propertyName
                ? `${hit.propertyName}, ${
                    !hit.propertyArea?.address_components[0].types.includes(
                      "plus_code"
                    )
                      ? hit.propertyArea?.address_components[0].long_name
                      : hit.propertyArea?.areaName.substring(
                          0,
                          hit.propertyArea?.areaName.indexOf(",")
                        )
                  },  ${hit.propertyTown.townName}`
                : `${
                    !hit.propertyArea?.address_components[0].types.includes(
                      "plus_code"
                    )
                      ? hit.propertyArea?.address_components[0].long_name
                      : hit.propertyArea?.areaName.substring(
                          0,
                          hit.propertyArea?.areaName.indexOf(",")
                        )
                  }, ${hit.propertyTown.townName}`}
            </h5>
          </div>
        </a>
      </Link>
    </div>
  );
}

export default HomePropertyHit;
